export const primaryColor = '#febd16'
export const whiteColor = '#fff'
export const redColor = '#F64E60'
export const blackColor = '#333'
export const lightGrayColor = '#d3d3d3'
export const BASE_URL = process.env.REACT_APP_SERVER_URL
export const surveySampleFileName = process.env.REACT_APP_DOWNLOAD_SAMPLE_SURVEY
export const downloadSampleSurveyExcelSheetLink = `${surveySampleFileName}`
export const GCP_BASE_URL = process.env.REACT_APP_GCP_FILE_BASE_URL
export const GCP_BUCKET = process.env.REACT_APP_GCP_BUCKET

export const fileUplaodStatus = {
  UploadToGCPFromFronEnd: 'UploadedFromFrontend',
  DownloadedToServer: 'DownloadedToServer',
  Processing: 'Processing',
  Processed: 'Processed',
  UploadedToCloudAfterProcessing: 'UploadedToCloudAfterProcessing',
  DeletedFromServerAfterProcessing: 'DeletedFromServerAfterProcessing',
  downloading: 'Downloading',
  Errored: 'Errored',
}

export type CompanyRoles = 'CompanyAdmin' | 'User' | 'LocationHead' | 'Auditor'
export type DatesmetronRoles = 'Superadmin' | 'DatesmetronAnalyst' | 'Supervisor' | 'Surveyor'
export type companyOrDatesmetronRole = CompanyRoles | DatesmetronRoles

export const fileTypeObj = {
  Ortho: 'Ortho',
  Pointcloud: 'Pointcloud',
  Dem: 'Dem',
  Snap: 'Snap',
  DemSnap: 'DemSnap',
  PicSnap: 'PicSnap',
  OrthoSnap: 'OrthoSnap',
  Images: 'Images',
  Shape: 'Shape',
  Contour: 'Contour',
}
export const surveyType = 'surveyType'
export const surveyTypeObj = {
  all: 'all',
  rejected: 'rejected',
}
export type StockyardType = 'Indoor' | 'Outdoor'

export const indoorStockyard = {label: 'Indoor', value: 'Indoor'}
export const outdoorStockyard = {label: 'Outdoor', value: 'Outdoor'}

export const getIsOutdoorStockyard = (stockyard: string) => stockyard === outdoorStockyard.value
