import moment from 'moment'
import {BASE_S3_URL} from '../../../../../config/aws-client-config'
import {fileTypeObj, GCP_BASE_URL, StockyardType} from '../../../../Utils/constants'
import {IStockyardFromServer} from '../../../company/model'
import {initialStateInterface} from '../EditApprovedPiles'
import {IRejectedSurveysReasonList} from '../Models/analystModel'
import {IFileData, IPilesApprovalSurveyFromServer, ISurveyFromStockyard} from '../Models/apiModel'
import {IPileApprovalList, pilePublishingStatus} from '../Models/pileApprovalRejectionModel'
import {
  CompanyAdminPendingCompleteCount,
  IClientSurveyCountList,
} from '../Models/supervisorDashboard'
import {getCompanyStockyardsByLocationId} from '../service'

export const getFileNameArray = (file: File) => file.name.replace(/ /g, '').split('.')

export const getFileName = (file: File) => getFileNameArray(file)?.slice(0, -1)?.join('.')

export const getFileExtension = (file: File) => {
  const fileNameArray = getFileNameArray(file)
  return fileNameArray[fileNameArray.length - 1]
}

export const formatCompanyPendingCompletedCountList = (
  list: CompanyAdminPendingCompleteCount[]
): IClientSurveyCountList[] => {
  return list.map((data) => {
    const {companyAdminId, companyAdminName, CompletedSurveys, PendingSurveys} = data

    return {
      clientId: companyAdminId,
      clientName: companyAdminName,
      completedPiles: CompletedSurveys,
      pendingPiles: PendingSurveys,
    }
  })
}

export const FormatSurveyDataList = (surveyData: ISurveyFromStockyard) => {
  return {
    name: surveyData.name,
    date: new Date(surveyData.date),
    time: surveyData.time,
    stockyardId: surveyData.Stockyard.id,
    bulkMaterialId: surveyData.BulkMaterial.id,
    departmentId: surveyData.Department.id,
    quantity: surveyData.quantity || 0,
    bunkerValue: surveyData.bunkerValue || 0,
    grnPending: surveyData.grnPending || 0,
    consumptionPending: surveyData.consumptionPending || 0,
    dispatchTransfer: surveyData.dispatchTransfer || 0,
    bulkDensity: surveyData.bulkDensity || 0,
    moisture: surveyData.moisture || 0,
    type: surveyData.type as StockyardType,
    additionalInformation: surveyData.additionalInformation || '',
    volume: surveyData.volume || 0,
    combinedPiles: !!surveyData.combinedPiles,
    obstructionStructure: !!surveyData.obstructionStructure,
    pileHealth: !!surveyData.pileHealth,
    standingWater: !!surveyData.standingWater,
    tarpaulinOnPile: !!surveyData.tarpaulinOnPile,
    vegetationGrowth: !!surveyData.vegetationGrowth,
    toeConfidence: surveyData?.toeConfidence || 'Low',
    surfaceConfidence: surveyData?.surfaceConfidence || 'Low',
  }
}

export const getImageFilterCondition = (file: IFileData) => file.file_type === fileTypeObj.Images

export const getFileLink = (fileName: string) => {
  if (!fileName) return ''
  return GCP_BASE_URL + fileName
}

export const formatPileApprovalList = (
  surveyData: IPilesApprovalSurveyFromServer[]
): IPileApprovalList[] => {
  return surveyData.map((pile) => {
    const {id, volume, isApproved} = pile
    const imageFile = pile.Files.find(getImageFilterCondition)
    const fileNameSurveyId = imageFile?.surveyId
    const stockyardId = imageFile?.stockyardId
    const dateString = imageFile?.dateStringPath
    const fileName = imageFile?.file_name
    const url = `${stockyardId}/${dateString}/${fileName}`
    const fileUrl = !!fileName && !!fileNameSurveyId ? url : ''
    const formattedDate = moment(new Date(pile.date))?.format('YYYY-MM-DD')
    return {
      id,
      name: pile.name,
      publishingStatus: pilePublishingStatus.notPublished,
      volume,
      volumetricCompetition: getFileLink(fileUrl || ''),
      files: pile.Files,
      isApproved,
      locationId: pile.Department.locationId,
      isEdited: false,
      pileCode: pile.pileCode,
      date: formattedDate,
    }
  })
}

export const formatRejectedReasonList = (
  data: ISurveyFromStockyard[]
): IRejectedSurveysReasonList[] => {
  return data.map(({pileCode, rejectedReason, id}) => ({id, pileCode, rejectedReason}))
}

export const getStockyardsByLocationId = async (locationId: string) => {
  try {
    const {data} = await getCompanyStockyardsByLocationId(locationId)
    return data
  } catch (error) {
    console.error('get stockyards by location error', error)
    return null
  }
}

export const findStockyardById = async (stockyardId: string, locationId: string) => {
  try {
    const stockyards = await getStockyardsByLocationId(locationId)
    const selectedStockyard =
      stockyards && stockyards?.length > 0
        ? stockyards?.find((stockyard) => stockyard.id === stockyardId)
        : null
    if (!selectedStockyard) return null
    return selectedStockyard
  } catch (error) {
    console.error('get selected stockyard error', error)
    return null
  }
}
